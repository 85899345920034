@import ../../../../styles/helpers

.preview
  position: relative
  max-height: 1024px
  &:before
    content: ""
    display: block
    padding-bottom: 60.63%
    +d
      padding-bottom: 64%
    +m
      display: none

.background
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  object-fit: cover
  +t
    border-radius: 0
  +m
    position: static
    height: 780px
    object-position: 50% 100%

.wrap
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  // display: flex
  justify-content: center
  padding-top: 80px
