@import ../../../styles/helpers

.dbcInformation
  .section
    +d
      margin-bottom: 96px
    +m
      margin-bottom: 64px

.wrap
  text-align: center
  padding-top: 120px
  margin: 0 auto 80px
  text-align: center
  +m
    max-width: 100%
    padding-top: 64px

.title
  margin-bottom: 12px

.subtitle
  margin-bottom: 32px
  margin-top: 12px
  font-size: 24px
  line-height: 1.3333333333
  letter-spacing: -0.01em
  color: $n4

.body
  position: relative
  max-width: 1024px
  margin: 0 auto

.preview
  display: flex
  justify-content: space-between
  align-items: center
  img:nth-child(1)
    flex: 1
    max-width: 35%
    margin-right: 40px
    margin-top: -50px
  img:nth-child(2)
    flex: 1.5
    max-width: 60%
  +m
    display: block
    img:nth-child(1)
      max-width: 100%
      margin-bottom: 40px
    img:nth-child(2)
      max-width: 100%
