
@import ../../../styles/helpers

.animationTeaser
  padding-bottom: 136px
  +m
    padding-bottom: 64px
  .container
    margin: 0 auto
    +m
      padding: 0 16px

.animationTeaser
  .container
    background-color: black
    position: relative

.wrap
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  // display: flex
  justify-content: center
  padding-top: 80px
  +d
    padding-top: 40px
  +t
    padding-top: 20px

.title
  text-align: center
  margin-bottom: 12px
  color: $n8
  +m
    margin-top: 40px

.subtitle
  text-align: center
  margin-top: 80px
  font-size: 32px
  line-height: 1.3333333333
  letter-spacing: -0.01em
  color: $n4
  margin-bottom: 80px
  +m
    margin-bottom: 160px
