@import ../../../styles/helpers

.main
  .section
    +d
      margin-bottom: 96px
    +m
      margin-bottom: 64px

.main
  .container
    max-width: 1440px
    +w
      padding: 0 40px
    +t
      padding: 0
    +m
      padding: 0 8px

.preview
  position: relative
  &:before
    content: ""
    display: block
    padding-bottom: 60.63%
    +d
      padding-bottom: 64%
    +m
      display: none

.background
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  border-radius: 24px
  object-fit: cover
  +t
    border-radius: 0
  +m
    position: static
    height: 780px
    border-radius: 16px 16px 0 0
    object-position: 50% 100%

.characters
  position: absolute
  bottom: 120px
  left: 50%
  transform: translateX(-50%)
  width: 80%
  height: auto
  +t
    bottom: 100px
  +m
    display: none

.wrap
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  padding-top: 20px
  +d
    padding: 48px 80px
  +t
    padding: 80px 40px
  +m
    padding: 220px 40px

.title
  font-size: 400px
  line-height: 0.7
  letter-spacing: 4px
  text-align: left
  color: $white
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5)
  +alumni-sans
  +d
    font-size: 320px
  +t
    font-size: 240px
  +m
    font-size: 200px
  +a
    font-size: 150px
  +s
    font-size: 100px

.panel
  margin: -105px 40px 0
  +d
    margin-top: -78px
  +t
    margin: -50px 16px 0
  +m
    margin: -180px 8px 0

.row
  display: flex
  +x
    margin: 0 -20px
  +t
    margin: 0
  +m
    display: block
  & > div
    flex: 1
    &:first-child
      flex: 1.5
