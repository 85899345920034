@import ../../../../styles/helpers

.item
  padding: 8px 8px 24px
  border-radius: 16px
  color: $n2
  transition: all .2s
  text-align: center

.preview
  position: relative
  margin: 0 auto 16px
  border-radius: 15px
  overflow: hidden
  &:before
    content: ''
    display: block
    padding-bottom: 100%
  img
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover
    border-radius: 50%,
    filter: grayscale(100%),
    background-color: $white,

.title
  margin-bottom: 4px
  text-align: center
  +body-bold-2
  +dark
    color: $n8

.name
  +body-2
  +dark
    color: $n8

.rank
  +caption-1
  +dark
    color: $n4

.career
  +caption-2
  color: $n4
