@import ../../../../styles/helpers

.panel
  position: relative
  z-index: 5
  padding: 40px
  +d
    padding: 32px 32px 24px
  +m
    padding: 32px 20px 24px

.background
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  overflow: hidden
  border-radius: 24px
  box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1)
  +m
    border-radius: 20px
    box-shadow: 0px 16px 32px rgba(15, 15, 15, 0.1)
  &:before
    content: ""
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: linear-gradient(83.59deg, #FCFCFD 36.52%, rgba(252, 252, 253, 0.83) 98.8%)
    border: 1px solid $n8
    backdrop-filter: blur(32px)
    border-radius: 24px
    +m
      border-radius: 20px
    +dark
      background: linear-gradient(92.98deg, #23262F 3.54%, rgba(35, 38, 47, 0.7) 93.38%)
      border-color: $n3

.body
  position: relative
  z-index: 3
