.slick-slider
  position: relative
  display: block
  box-sizing: border-box
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
  -webkit-touch-callout: none
  -khtml-user-select: none
  -ms-touch-action: pan-y
  touch-action: pan-y
  -webkit-tap-highlight-color: transparent

.slick-list
  position: relative
  display: block
  overflow: hidden
  margin: 0
  padding: 0
  &:focus
    outline: none
  &.dragging
    cursor: pointer
    cursor: hand

.slick-slider
  .slick-track, .slick-list
    -webkit-transform: translate3d(0, 0, 0)
    -moz-transform: translate3d(0, 0, 0)
    -ms-transform: translate3d(0, 0, 0)
    -o-transform: translate3d(0, 0, 0)
    transform: translate3d(0, 0, 0)

.slick-track
  position: relative
  top: 0
  left: 0
  display: block
  margin-left: auto
  margin-right: auto
  &:before
    display: table
    content: ''
  &:after
    display: table
    content: ''
    clear: both

.slick-loading .slick-track
  visibility: hidden

.slick-slide
  display: none
  float: left
  height: 100%
  min-height: 1px

[dir='rtl'] .slick-slide
  float: right

.slick-slide
  img
    display: block
  &.slick-loading img
    display: none
  &.dragging img
    pointer-events: none

.slick-initialized .slick-slide
  display: block

.slick-loading .slick-slide
  visibility: hidden

.slick-vertical .slick-slide
  display: block
  height: auto
  border: 1px solid transparent

.slick-arrow.slick-hidden
  display: none

.slick-arrow
  position: absolute
  z-index: 2
  width: 40px
  height: 40px
  border-radius: 50%
  font-size: 0
  transition: all .2s
  svg
    position: relative
    z-index: 2
    fill: $n4
    transition: fill .2s
  &:hover
    box-shadow: inset 0 0 0 2px $n4
  +dark-common
    &:hover
      box-shadow: inset 0 0 0 2px $n4
      svg
        fill: $n4
    // &.slick-disabled
    //     opacity: .7

// playing slider
.playing-slider
  // min-height: 544px
  .slick-list
    overflow: visible
  .slick-slide
    opacity: 0
    visibility: hidden
    transition: all .4s
    &.slick-active
      opacity: 1
      visibility: visible
      .playing-preview
        transform: scale(1)
        opacity: 1
      .playing-bg
        img
          transform: scale(1)
  .slick-arrow
    top: 50%
    svg
      fill: $n4
    +dark-common
      &:hover
        box-shadow: inset 0 0 0 2px $n4
    +m
      top: auto
      bottom: -56px
  .slick-prev
    left: -96px
    +w
      left: -64px
    +d
      left: -48px
    +t
      left: -48px
    +m
      left: calc(50% - 48px)
  .slick-next
    right: -96px
    +w
      right: -64px
    +d
      right: -48px
    +t
      right: -48px
    +m
      right: calc(50% - 48px)

// playing slider
.playing-slider
  // min-height: 544px
  .slick-list
    overflow: visible
  .slick-slide
    opacity: 0
    visibility: hidden
    transition: all .4s
    &.slick-active
      opacity: 1
      visibility: visible
      .playing-preview
        transform: scale(1)
        opacity: 1
      .playing-bg
        img
          transform: scale(1)
  .slick-arrow
    top: 50%
    svg
      fill: $n4
    +dark-common
      &:hover
        box-shadow: inset 0 0 0 2px $n4
    +m
      top: auto
      bottom: -56px
  .slick-prev
    left: -96px
    +w
      left: -64px
    +d
      left: -48px
    +t
      left: -48px
    +m
      left: calc(50% - 48px)
  .slick-next
    right: -96px
    +w
      right: -64px
    +d
      right: -48px
    +t
      right: -48px
    +m
      right: calc(50% - 48px)

// shpooing slider
.shopping-slider
  +m
    padding-bottom: 80px
  .slick-list
    overflow: visible
    +m
      margin: 0 -144px 0 0
  .slick-slide
    padding: 0 8px
    opacity: 0
    visibility: hidden
    transition: all .4s
    &.slick-active
      opacity: 1
      visibility: visible
  .slick-arrow
    bottom: 0
  .slick-prev
    left: calc(50% - 48px)
  .slick-next
    right: calc(50% - 48px)

// shpooing slider
.officer-slider
  +m
    padding-bottom: 80px
  .slick-list
    overflow: visible
    +m
      margin: 0 -144px 0 0
  .slick-slide
    padding: 0 8px
    opacity: 0
    visibility: hidden
    transition: all .4s
    &.slick-active
      opacity: 1
      visibility: visible
  .slick-arrow
    bottom: 0
  .slick-prev
    left: calc(50% - 48px)
  .slick-next
    right: calc(50% - 48px)
