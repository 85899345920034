@import ../../../../styles/helpers

.preview
  position: relative
  max-height: 1024px
  &:before
    content: ""
    display: block
    padding-bottom: 60.63%
    +d
      padding-bottom: 64%
    +m
      display: none

.background
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  object-fit: cover
  +t
    border-radius: 0
  +m
    position: static
    height: 780px
    object-position: 50% 100%

.wrap
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  // display: flex
  justify-content: center
  padding-top: 80px
  +d
    padding-top: 40px
  +t
    padding-top: 20px

.title
  text-align: center
  margin-bottom: 12px
  color: $n8
  +m
    margin-top: 40px

.subtitle
  text-align: center
  margin-top: 12px
  font-size: 32px
  line-height: 1.3333333333
  letter-spacing: -0.01em
  color: $n4
  margin-bottom: 32px

.videoWrapper
  position: absolute
  left: 50%
  transform: translateX(-50%)
  border-radius: 24px
  overflow: hidden
  bottom: 0
  width: calc(1419px - 260px) !important
  box-shadow: 0 0 15px rgba(49, 64, 119, 0.8)
  margin: 0 auto
  +w
    width: calc(1419px - 360px) !important
  +x
    width: calc(1339px - 360px) !important
  +d
    width: calc(1023px - 360px) !important
    bottom: 100px
  +t
    width: calc(700px - 160px) !important
    bottom: 40px
  +m
    width: 10calc(639px - 160px) !important
    bottom: 180px
  +a
    max-width: calc(474px - 16px) !important
  & > div
    width: 100% !important
    height: auto !important
