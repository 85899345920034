@import ../../styles/helpers

.field
  &.fieldIcon
    .input
      padding-left: 48px
  &.error
    margin-bottom: 0px
    .icon
      svg
        fill: $s4
    .wrap
      &:after
        content: ""
        position: absolute
        top: 0
        right: 0
        bottom: 0
        width: 48px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath d='M12 15a1 1 0 0 0 1-1V9a1 1 0 1 0-2 0v5a1 1 0 0 0 1 1zm7.675 2.504L12.87 5.563a1 1 0 0 0-1.738 0L4.327 17.504A1 1 0 0 0 5.196 19h13.61a1 1 0 0 0 .869-1.495zM14.607 4.573c-1.151-2.019-4.062-2.019-5.213 0L2.589 16.514C1.45 18.514 2.894 21 5.196 21h13.61c2.302 0 3.746-2.486 2.606-4.485L14.607 4.573z' fill-rule='evenodd' fill='%23ff6a55'/%3E%3Cpath d='M13 17a1 1 0 1 1-2 0 1 1 0 1 1 2 0z' fill='%23ff6a55'/%3E%3C/svg%3E") no-repeat 50% 50% / 24px auto
    .input
      padding-right: 48px
      background: rgba($s1, .25)
      border-color: transparent
      color: $s4
      +dark
        &:focus
          border-color: $s4
          color: $s4
      &::placeholder
        color: $s4

.wrap
  position: relative

.input
  width: 100%
  height: 48px
  padding: 0 10px
  border-radius: 12px
  border: 2px solid transparent
  background: $n7
  +noto-sans
  +caption-bold-1
  color: $n2
  transition: all .2s
  +dark
    border-color: $n3
    background: $n3
    color: $n8
  +placeholder
    color: $n4
    +dark
      color: $shades1
  &:focus
    border-color: $shades1
    background: $white
    +dark
      border-color: $n3
      background: $n1

.icon
  position: absolute
  top: 0
  left: 0
  bottom: 0
  display: flex
  justify-content: center
  align-items: center
  width: 48px
  pointer-events: none
  svg
    fill: $n4
