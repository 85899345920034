@import ../../../styles/helpers

.officer
  background-color: $n7
  padding-bottom: 136px
  +dark
    background-color: $n2
  .section
    padding-top: 80px
    margin-bottom: 0
  .container
    max-width: 1440px
    +w
      padding: 0 40px
    +t
      padding: 0

.head
  margin-bottom: 80px
  text-align: center
  +d
    margin-bottom: 64px
  +m
    margin-bottom: 40px

.title
  margin-bottom: 12px

.slider
  display: flex
  flex-wrap: wrap
  justify-content: center
  margin: -64px -10px 0
  +x
    margin: -48px -16px 0
  +m
    display: block
    margin: 0

.item
  flex: 0 0 calc(20% - 48px)
  width: calc(20% - 48px)
  margin: 64px 24px 0
  flex: 0 0 calc(25% - 32px)
  width: calc(25% - 32px)
  +t
    flex: 0 0 calc(33.333% - 32px)
    width: calc(33.333% - 32px)
  +m
    display: block
    width: 100%
    margin: 0
