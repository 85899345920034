@import ../../../styles/helpers

.main
  .section
    +d
      margin-bottom: 96px
    +m
      margin-bottom: 64px

.main
  .container
    max-width: 1440px
    +w
      padding: 0 40px
    +t
      padding: 0
    +m
      padding: 0 8px

.preview
  position: relative
  &:before
    content: ""
    display: block
    padding-bottom: 60.63%
    +d
      padding-bottom: 64%
    +m
      display: none

.background
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  border-radius: 24px
  object-fit: cover
  +t
    border-radius: 0
  +m
    position: static
    height: 780px
    border-radius: 16px 16px 0 0
    object-position: 50% 100%

.characters, .wrap, .panel
  opacity: 1
  visibility: visible
  transition: opacity 0.5s ease, visibility 0.5s ease

.showCharacter .characters, .showCharacter .wrap, .showCharacter .panel
  opacity: 0
  visibility: hidden

.showCharacter .close, .showCharacter .characterContainer
  visibility: visible
  opacity: 1

.close, .characterContainer
  opacity: 0
  visibility: hidden
  transition: opacity 0.3s ease, visibility 0.3s ease

.characters
  position: absolute
  bottom: 120px
  left: 50%
  transform: translateX(-50%)
  width: 80%
  height: auto
  +t
    bottom: 100px
  +m
    display: none

.wrap
  position: absolute
  top: 0
  left: 0
  width: 100%
  height: 100%
  display: flex
  justify-content: center
  padding-top: 20px
  +d
    padding: 48px 80px
  +t
    padding: 80px 40px
  +m
    padding: 220px 40px

.title
  font-size: 400px
  line-height: 0.7
  letter-spacing: 4px
  text-align: left
  color: $white
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5)
  +alumni-sans
  +d
    font-size: 320px
  +t
    font-size: 240px
  +m
    font-size: 200px
  +a
    font-size: 150px
  +s
    font-size: 100px

.panel
  margin: -105px 40px 0
  +d
    margin-top: -78px
  +t
    margin: -50px 16px 0
  +m
    margin: -180px 8px 0

.row
  display: flex
  +x
    margin: 0 -20px
  +t
    margin: 0
  +m
    display: block
  & > div
    flex: 1
    &:first-child
      flex: 1.5

.leftTop, .leftBottom, .rightTop, .rightMiddle, .rightBottom
  border-radius: 24px
  background-color: rgba(252, 252, 253, 0.8)
  margin-bottom: 20px
  +m
    margin-bottom: 8px
  +dark
    background-color: rgba(35, 38, 47, 0.7)

.leftSide
  position: absolute
  height: calc(100% - 40px)
  width: calc(38% - 30px)
  top: 0
  left: 0
  margin: 20px
  +m
    width: calc(50% - 12px)
    height: calc(100% - 16px)
    margin: 8px

.leftTop
  height: calc(80% - 20px)
  display: flex
  align-items: center
  justify-content: center
  +m
    height: calc(80% - 8px)
  img
    height: 67%
    +m
      width: 80%

.leftBottom
  height: 20%
  padding: 30px
  display: flex
  align-items: center
  justify-content: center
  +body-1
  +m
    +body-2

.rightSide
  position: absolute
  height: calc(100% - 40px)
  width: calc(62% - 30px)
  top: 0
  left: calc(38% - 10px)
  margin: 20px
  +m
    height: calc(100% - 16px)
    width: calc(50% - 12px)
    left: calc(50% - 4px)
    margin: 8px

.rightTop
  &::-webkit-scrollbar
    width: 6px
  &::-webkit-scrollbar-thumb
    background-color: rgba(166, 166, 166, 0.5)
    border-radius: 4px
  &::-webkit-scrollbar-track
    background: lightgrey
    border-radius: 4px
  &*
    scrollbar-width: thin
    scrollbar-color: rgba(166, 166, 166, 0.5) lightgrey

.rightTop
  overflow-y: scroll
  overflow-x: hidden
  height: calc(60% - 40px)
  display: flex
  flex-wrap: wrap
  justify-content: center
  +m
    height: calc(60% - 8px)
  .infoTitle
    width: 100%
    margin-right: 20px
    margin: 15px 0 0 20px
    +body-1
    +t
      font-size: 18px
    +m
      +body-2
  .achievement
    position: relative
    flex: 0 0 calc(25% - 10px)
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    min-width: 140px
    margin: 5px
    text-align: center
    img
      max-width: 100px
      width: 100%
      padding-left: 12px
      +t
        max-width: 80px
    .achievementDate
      +caption-2
      min-height: 20px
    .achievementTitle
      +caption-bold-1
      +m
        +caption-bold-2

.rightBottom
  +m
    height: 20% !important

.rightMiddle, .rightBottom
  height: 20%
  padding: 30px
  display: flex
  align-items: center
  +m
    padding: 10px
    display: block
    height: calc(20% - 8px)
  .infoTitle
    margin-right: 20px
    +body-1
    +t
      font-size: 18px
    +m
      +body-2
      margin: 5px 0 15px 10px
  .infoBox
    height: 48px
    position: absolute
    right: 30px
    display: flex
    justify-content: center
    align-items: center
    border-radius: 50px
    width: 75%
    background-color: $n7
    color: $n4
    border: 1px solid $n5
    +m
      width: calc(100% - 20px)
      right: 10px
    +dark
      background-color: $n2
      border: 1px solid $n4

.close
  position: absolute
  top: 10px
  right: 10px
  width: 32px
  height: 32px
  background: $p1
  border-radius: 50%
  box-shadow: 0px 8px 16px rgba(15, 15, 15, 0.1)
  font-size: 0
  svg
    fill: $n8
    transition: transform .2s
  +dark
    border-color: $n3
    background: $n2
    svg
      fill: $n4
  &:hover
    svg
      transform: rotate(90deg)
