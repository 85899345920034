@import ../../../styles/helpers

.shopping
  .section
    margin-top: 80px

.shopping
  .container
    max-width: 1440px
    +w
      padding: 0 40px
    +t
      padding: 0

.inner
  padding: 112px 80px
  background: $n7
  border-radius: 24px
  +w
    padding: 80px 64px
  +x
    padding: 64px 40px
  +t
    border-radius: 0
  +m
    padding: 64px 24px
  +dark
    background: #18191D
    border-width: 1px
    border-style: solid
    border-color: $n3
    +t
      border-width: 1px 0

.head
  margin-bottom: 80px
  text-align: center
  +d
    margin-bottom: 64px
  +m
    margin-bottom: 40px

.title
  margin-bottom: 12px

.subtitle
  margin-top: 12px
  font-size: 24px
  line-height: 1.3333333333
  letter-spacing: -0.01em
  color: $n4
  margin-bottom: 32px

.info
  margin-bottom: 40px
  font-size: 16px
  line-height: (24/16)
  font-weight: 400
  color: $n4

.slider
  display: flex
  flex-wrap: wrap
  justify-content: center
  margin: -64px -10px 0
  +x
    margin: -48px -16px 0
  +m
    display: block
    margin: 0

.item
  flex: 0 0 calc(20% - 48px)
  width: calc(20% - 48px)
  margin: 64px 24px 0
  +x
    flex: 0 0 calc(20% - 32px)
    width: calc(20% - 32px)
    margin: 48px 16px 0
  +d
    flex: 0 0 calc(25% - 32px)
    width: calc(25% - 32px)
  +t
    flex: 0 0 calc(33.333% - 32px)
    width: calc(33.333% - 32px)
  +m
    display: block
    width: 100%
    margin: 0
  &:nth-child(n+8)
    +d
      display: none
    +t
      display: block
