@import ../../../../styles/helpers

.form
  .formTitle
    margin-bottom: 20px
    +body-bold-2
  .button
    width: 100%
    margin-bottom: 12px

.field
  margin-bottom: 12px

.loader
  transform: scale(.8)
  margin: 0 18px 0 8px
  &.hide
    display: none

.invalid
  margin-top: 4px
  margin-bottom: 12px
  +caption-1
  color: $s4
  +dark
    color: $s4
  &.hide
    display: none

.note
  margin-top: 32px
  +caption-1
  font-weight: 400
  color: $shades1
