@import ../../styles/helpers

.header
  position: relative
  z-index: 10
  padding: 24px 0
  +m
    padding: 48px 0 24px

.container
  display: flex
  align-items: center

.logo
  margin-right: 40px
  +d
    margin-right: 32px
  +m
    position: relative
    z-index: 15
    margin-right: auto
  img
    width: 160px

.wrapper
  display: flex
  align-items: center
  flex-grow: 1
  padding-left: 40px
  +d
    padding-left: 32px
  +t
    padding-left: 0
    border: none
  +m
    position: absolute
    top: 0
    left: 0
    right: 0
    z-index: 10
    flex-direction: column
    align-items: stretch
    height: 100vh
    min-height: auto
    padding: 132px 32px 48px
    background: $n8
    visibility: hidden
    opacity: 0
    pointer-events: none
    transition: all .3s
    +dark
      background: $n1
    &.active
      visibility: visible
      opacity: 1
      pointer-events: all
  +dark
    border-color: $n2
  & > div
    &:first-child
      // now it's .language's parent div auto 
      // if add more divs, this will be changed
      margin-left: auto

.language
  +m
    display: none
    margin: 0

.btns
  display: flex
  align-items: center
  margin-left: 40px
  +button-2
  +m
    margin-left: 0
  .link
    margin-right: 32px
    color: $n4
    &:hover,
    &.active
      color: darken($p1, 10)

.user
  margin-left: 24px
  +m
    margin-left: 20px
