@import ../../../styles/helpers

.whitepaper
  .section
    +d
      margin-bottom: 96px
    +m
      margin-bottom: 64px

.wrap
  text-align: center
  max-width: 440px
  padding-top: 120px
  margin: 0 auto 80px
  text-align: center
  +m
    max-width: 100%
    padding-top: 64px

.title
  margin-bottom: 12px

.subtitle
  margin-bottom: 32px
  margin-top: 12px
  font-size: 24px
  line-height: 1.3333333333
  letter-spacing: -0.01em
  color: $n4

.body
  position: relative
  max-width: 1024px
  margin: 0 auto

.list
  position: relative
  display: flex
  +m
    display: block
    max-width: 232px
    margin: 0 auto

.item
  padding: 10px
  text-align: center
  color: inherit
  text-decoration: none
  &:not(:last-child)
    margin-right: auto
    +m
      margin: 0 0 64px
  & svg
    fill: $n7
    +dark
      fill: $n2
    +t
      width: 197px
      height: 215px
    +m
      width: 185px
      height: 202px

.preview
  position: relative

.overlay
  position: absolute
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  & svg
    fill: #CA5454
    +dark
      fill: #CA5454
    +t
      width: 90px
      height: 100px
    +m
      width: 84px
      height: 94px

.language
  margin-top: 20px
  +body-bold-1
