@import ../../../../styles/helpers

.slickCard
  .section
    position: relative
    display: flex
    align-items: center
    margin-bottom: 0

.slickCard
  .container
    position: relative
    overflow: hidden
    background-color: white
    // 슬라이더 사용시 주석 해제
    // border-radius: 24px
    padding: 0 50px
    height: 600px
    display: flex
    align-items: center
    +t
      height: 500px
    +m
      height: auto
      display: block
    +dark
      background-color: $n1

.wrap
  max-width: 440px
  .infoBox
    height: 48px
    display: flex
    justify-content: center
    align-items: center
    margin-top: 40px
    border-radius: 50px
    width: 95%
    background-color: $n7
    color: $n4
    +dark
      background-color: $n2
  +m
    max-width: 100%
    margin-bottom: 48px
    .button
      margin-top: 24px

.title
  margin-bottom: 12px
  font-size: 24px
  +m
    margin-top: 50px

.info
  font-size: 16px
  line-height: (24/16)
  margin-bottom: 32px
  font-weight: 400
  color: $n4

.preview
  position: absolute
  bottom: 0
  right: calc(50% - 600px)
  height: 543px
  +d
    height: 500px
  +t
    height: 400px
  +m
    position: relative
    width: 100%
    display: flex
    justify-content: center
    align-items: center
    right: auto
  img
    height: 100%
