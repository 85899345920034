@import ../../../../styles/helpers

.item
  padding: 8px 8px 24px
  border-radius: 16px
  background: $n8
  color: $n2
  transition: all .2s
  &:hover
    box-shadow: inset 0 0 0 1px $n5
  +dark
    background: $n2
    &:hover
      box-shadow: inset 0 0 0 1px $n3

.preview
  position: relative
  margin: 0 auto 16px
  border-radius: 15px
  overflow: hidden
  &:before
    content: ''
    display: block
    padding-bottom: 100%
  img
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    object-fit: cover

.title
  margin-bottom: 4px
  text-align: center
  +body-bold-2
  +dark
    color: $n8
