@import ../../../styles/helpers

.head
  margin-bottom: 64px
  text-align: center
  +m
    margin-bottom: 32px

.info
  margin-top: 12px
  font-size: 24px
  line-height: 1.3333333333
  letter-spacing: -0.01em
  color: $n4

.body
  position: relative
  max-width: 1024px
  margin: 0 auto

.line
  position: absolute
  top: 66px
  left: 50%
  transform: translateX(-50%)
  +t
    top: 24px
  +m
    display: none
  +dark
    path
      stroke: $n3 !important

.list
  position: relative
  z-index: 3
  display: flex
  +m
    display: block
    max-width: 256px
    margin: 0 auto

.item
  flex: 0 0 256px
  text-align: center
  +t
    flex: 0 0 200px
  &:not(:last-child)
    margin-right: auto
    +m
      margin: 0 0 64px

.preview
  img
    width: 100%

.subtitle
  margin-bottom: 8px
  font-size: 24px
  line-height: (32/24)
  font-weight: 600
  letter-spacing: -.01em

.text
  fint-size: 14px
  font-weight: 400
  color: $n4
