@import ../../styles/helpers

.message
  align-items: center
  color: $s4
  display: flex
  padding: 16px
  background-color: lighten($s4, 25)
  border-radius: 12px
  margin-bottom: 12px
  gap: 1rem
  +caption-1
  div
    flex: 1 1 0%
  svg
    fill: $s4
  .close
    svg
      transition: transform .2s
    &:hover
      svg
        transform: rotate(90deg)
  &.hide
    display: none
