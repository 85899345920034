@import ../../../styles/helpers

.playing
  background-color: $n7
  padding-bottom: 120px
  +m
    padding-bottom: 76px
  .container
    margin: 0 auto
    // 슬라이더 사용시 주석 해제 
    // padding: 0 80px
    // max-width: 1132px
    +m
      padding: 0 16px
  +dark
    background-color: $n2

.wrap
  text-align: center
  max-width: 440px
  padding-top: 120px
  margin: 0 auto
  text-align: center
  +m
    max-width: 100%
    padding-top: 64px

.title
  margin-bottom: 12px

.subtitle
  margin-bottom: 32px
  margin-top: 12px
  font-size: 24px
  line-height: 1.3333333333
  letter-spacing: -0.01em
  color: $n4

.info
  margin-bottom: 60px
  font-size: 16px
  line-height: (24/16)
  font-weight: 400
  color: $n4
