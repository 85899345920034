@import ../../../styles/helpers

.language
  position: relative
  &.active
    .head
      color: $n2
      svg
        fill: $n2
      +dark
        color: $n6
        svg
          fill: $n6
    .body
      visibility: visible
      opacity: 1
      transform: translateY(0)

.head
  display: flex
  align-items: center
  +button-2
  color: $n4
  transition: color .2s
  svg
    margin-right: 8px
    fill: $n4
    transition: all .2s
  &:hover
    color: $n2
    svg
      fill: $n2
    +dark
      color: $n6
      svg
        fill: $n6

.body
  position: absolute
  top: calc(100% + 43px)
  left: -40px
  // width: 292px
  width: 200px // 변경된 부분
  padding: 16px
  overflow: hidden
  border-radius: 20px
  box-shadow: 0px 40px 32px -8px rgba(15, 15, 15, 0.12)
  background: $n8
  visibility: hidden
  opacity: 0
  transform: translateY(10px)
  transition: all .3s
  +dark
    background: linear-gradient(92.98deg, #23262F 3.54%, rgba(35, 38, 47, 0.7) 93.38%)

.list
  display: flex
  // flex-wrap: wrap
  // margin: -4px -2px 0
  flex-direction: column // 이 부분 추가
  margin: -4px 0 0 // -2px를 0으로 변경

.item
  // flex: 0 0 calc(50% - 4px)
  // width: calc(50% - 4px)
  // margin: 4px 2px 0
  flex: 0 0 100% // 변경된 부분
  width: 100% // 변경된 부분
  margin: 4px 0 // -2px를 0으로 변경
  padding: 12px 20px
  border-radius: 8px
  transition: background .2s
  &:hover,
  &.active
    background: $n7
    +dark
      background: $n1
  &.active
    .title
      color: $n2
      +dark
        color: $n6

.title
  +button-2
  color: $n4
  transition: color .2s
  +dark
    color: $n6

.country
  +caption-2
  color: $n5
