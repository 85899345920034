@import ../../../../styles/helpers

.head
  position: relative
  border-radius: 16px
  user-select: none
  transition: all .2s

.icon
  position: absolute
  top: 20px
  left: 16px
  display: flex
  justify-content: center
  align-items: center
  width: 32px
  height: 32px
  pointer-events: none
  +d
    left: 10px
  +t
    display: none
  +m
    top: 8px
    left: 0
    display: flex
    width: 24px
  svg
    fill: $n5
    +d
      width: 20px
      height: 20px

.title
  display: flex
  width: 100%
  height: 96px
  padding: 20px 80px 0 56px
  border-radius: 16px
  +text-overflow
  +body-bold-1
  color: $n2
  cursor: pointer
  +d
    padding: 25px 64px 0 48px
    font-size: 18px
  +t
    height: 64px
    padding: 12px 64px 0 0
  +m
    padding-left: 32px
  +dark
    color: $n8

.description
  position: absolute
  left: 56px
  bottom: 20px
  pointer-events: none
  +body-2
  color: $n4
  +d
    left: 48px
    font-size: 14px
  +t
    left: 0
    bottom: 4px
  +m
    left: 32px
