@import ../../../styles/helpers

.characters
  width: 100%
  display: flex
  justify-content: center
  align-items: center
  position: relative

.characters
  .section
    margin-top: 80px

.characters
  .container
    max-width: 1440px
    +w
      padding: 0 40px
    +t
      padding: 0

.characters
  .inner
    display: flex
    justify-content: center
    align-items: center
    +m
      width: 100%
      flex-direction: column

.inner
  padding: 112px 80px
  background: $n7
  border-radius: 24px
  +w
    padding: 80px 64px
  +x
    padding: 64px 40px
  +t
    border-radius: 0
  +m
    padding: 64px 24px
  +dark
    background: $n2
    border-width: 1px
    border-style: solid
    border-color: $n3
    +t
      border-width: 1px 0

.wrap
  text-align: center

.topLink
  color: $n4
  position: absolute
  top: 46px
  right: 36px
  +t
    padding-left: 80px
  +m
    right: 24px
  +caption-1
  color: $shades1
  a
    font-weight: 700
    color: $n2
    transition: color .2s
    +dark
      color: $n8
    &:hover
      color: $p1
      +dark
        color: $p1

.gallery
  width: 50%
  height: 100%
  min-height: 60vh
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center

.wrap
  width: 50%
  height: 100%
  min-height: 60vh
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  +m
    min-height: 50vh
  .title
    font-size: 48px
    font-weight: 500
    line-height: 1.2
    letter-spacing: -.02em
    margin: 16px auto 32px
    width: 80%
    +m
      width: 100%
  .subtitle
    font-size: 20px
    margin: 16px auto 32px
    width: 80%
    +m
      width: 100%
  .info
    font-size: 16px
    margin: 16px auto
    font-weight: 400
    color: $n4
    width: 80%
    +m
      width: 100%
  +m
    width: 80%
