
.animation-gallery
  .image-gallery-slides
    border-radius: 24px
    height: 100%

.animation-gallery
  .image-gallery-thumbnail .image-gallery-thumbnail-inner img
    border-radius: 10px

.animation-gallery
  .image-gallery-thumbnail
    border-radius: 14px
    &:hover, &:active
      border-color: $p1

.animation-gallery
  .image-gallery-thumbnail.active, .image-gallery-thumbnail:focus
    border-color: $p1

.animation-gallery .fullscreen .image-gallery-image
  max-height: calc(100vh - 80px)

.animation-gallery
  .image-gallery
    position: absolute
    top: 0
    left: 0
    // max-width: 1440px
    max-width: calc(1419px - 260px)
    left: 50%
    transform: translateX(-50%)
    +w
      max-width: calc(1419px - 360px)
    +x
      max-width: calc(1339px - 360px)
    +d
      max-width: calc(1023px - 360px)
    +t
      max-width: calc(700px - 160px)
    +m
      max-width: calc(639px - 160px)
    +a
      max-width: calc(474px - 16px)
