@import ../../../styles/helpers
@import ../auth_helpers

.auth
  display: flex
  justify-content: center
  align-items: center
  padding: 48px
  background: $n8
  +m
    padding: 24px
  +dark
    background: $n2

.topLink
  position: absolute
  top: 46px
  right: 36px
  +t
    padding-left: 80px
  +m
    right: 24px
  +text-link

.wrapper
  padding: 96px 0
  +wrapper

.header
  .logo
    +header-logo
    margin-bottom: 30px
  .title
    +header-title
