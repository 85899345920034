
= wrapper
  max-width: 296px
  width: 100%
  +m
    max-width: 100%

= header-logo
  display: inline-block
  width: 62px
  img
    width: 100%

= header-title
  margin-bottom: 30px

= text-link
  +caption-1
  color: $shades1
  a
    font-weight: 700
    color: $n2
    transition: color .2s
    +dark
      color: $n8
    &:hover
      color: $p1
      +dark
        color: $p1
