@import ../../../styles/helpers

.learning
  .section
    position: relative
    display: flex
    align-items: center
    min-height: 750px
    padding: 50px 0 150px
    +d
      min-height: 700px
    +t
      min-height: 600px
      padding: 50px 0 100px
    +m
      min-height: auto
      padding: 0

.wrap
  max-width: 440px
  +m
    max-width: 100%
    margin-bottom: 48px
    text-align: center

.title
  margin-bottom: 12px

.subtitle
  margin-top: 12px
  font-size: 24px
  line-height: 1.3333333333
  letter-spacing: -0.01em
  color: $n4
  margin-bottom: 32px

.button
  margin-top: 20px
  width: 90%

.info
  margin-bottom: 40px
  font-size: 16px
  line-height: (24/16)
  font-weight: 400
  color: $n4

.gallery
  position: absolute
  top: 0
  right: calc(50% - 600px)
  width: 643px
  z-index: -1
  +d
    width: 600px
  +t
    width: 500px
  +m
    position: relative
    top: auto
    right: auto
    width: 100%

.preview
  &:nth-child(2)
    left: 1.5%
    bottom: 16.5%
    width: 39.7%
    filter: drop-shadow(0px 40px 64px rgba(15, 15, 15, 0.1))
  &:nth-child(3)
    right: 10%
    bottom: 27.5%
    width: 30.8%
    filter: drop-shadow(0px 40px 32px rgba(15, 15, 15, 0.12))
  &:not(:first-child)
    position: absolute
  img
    width: 100%
