@import '../../../styles/helpers'
@import ../auth_helpers

.auth
  position: relative
  display: flex
  background: $n8
  +dark
    background: $n2

.topLink
  position: absolute
  top: 46px
  right: 36px
  +t
    padding-left: 80px
  +m
    right: 24px
  +text-link

.col
  padding: 96px 24px
  +m
    padding-bottom: 32px
  &:first-child
    display: flex
    justify-content: center
    align-items: center
    flex-shrink: 0
    width: 400px
    background: $n7
    +t
      display: none
    +dark
      background: $n1
  &:nth-child(2)
    display: flex
    justify-content: center
    align-items: center
    flex-grow: 1

.sidebar
  max-width: 212px
  .preview
    max-width: 200px
    margin: 0 auto 40px
    img
      width: 100%
  .subtitle
    margin-bottom: 48px
    text-align: center
  .list
    margin-left: 40px
    li
      position: relative
      padding-left: 36px
      +caption-bold-1
      color: $n4
      &:before
        content: ""
        position: absolute
        top: 0
        left: 0
        width: 24px
        height: 24px
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'%3E%3Cpath fill-rule='evenodd' d='M22 12c0 5.523-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2s10 4.477 10 10zm-2 0a8 8 0 1 1-16 0 8 8 0 0 1 8-8 7.98 7.98 0 0 1 5.942 2.644L11 13.586l-2.793-2.793a1 1 0 0 0-1.414 1.414L9.586 15a2 2 0 0 0 2.828 0l6.687-6.687C19.676 9.416 20 10.67 20 12z' fill='%2383bf6e'/%3E%3C/svg%3E") no-repeat 50% 50% / 100% auto
      &:not(:last-child)
        margin-bottom: 20px

.header
  .logo
    display: inline-block
    position: absolute
    top: 24px
    left: 24px
    z-index: 2
    width: 62px
    img
      width: 100%

.wrapper
  padding: 96px 0
  +wrapper
  .title
    +header-title
